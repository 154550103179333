import React, { useState, useContext } from "react";
import { Form, Button, Modal, Alert } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import NotFound from "../../components/NotFound";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";
import Select from "react-select";

import NotyfContext from "../../contexts/NotyfContext";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshData } from "../../redux/slices/refreshData";

import axios from "axios";
import { apiConfig } from "../../config";

import AddEventFilter from "./components/AddEventFilter";
import IsAllowed from "../../components/IsAllowed";

const NotificationsTable = () => {
  const { userAccessToken, user } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const refreshData = useSelector((state) => state.refreshdata);

  const dispatch = useDispatch();

  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const initOpenModals = () => {
    let modals = {};
    return modals;
  };
  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };
  const [passedData, setPassedData] = useState({});
  const passData = (data) => {
    setPassedData(data);
  };

  const subscriptionsColumns = [
    {
      Header: "Notify me when",
      accessor: "event_friendly_name",
    },
    {
      Header: "Delivery Method",
      accessor: "delivery_method",
      Cell: (e) =>
        e.value === "email"
          ? "app & email"
          : e.value === "sms"
          ? "app & sms"
          : e.value === "*"
          ? "app, email & sms"
          : "app",
    },
    {
      Header: "Filters",
      accessor: "event_filters",
      Cell: ({ row }) => (
        <>
          {row.original.event_filters === null ? (
            "0 filters"
          ) : (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                passData(row.original);
                toggle("viewSubscriptionFilters");
              }}
            >
              {row?.original?.event_filters?.length + " filters"}
            </a>
          )}
        </>
      ),
    },
    {
      Header: "Group",
      Cell: ({ row }) => (
        <>
          {row.original.group_name === null ? "None" : row.original.group_name}
        </>
      ),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <>
          <FontAwesomeIcon
            icon={faTrash}
            className="ms-2"
            onClick={() => {
              deleteSubscription(row.original.subscription_id);
              setTimeout(() => {
                dispatch(setRefreshData());
              }, 2000);
            }}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },
  ];

  const deleteSubscription = (subscriptionId) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .delete(
        `${apiConfig.usersApiUrl}notifications/subscriptions/${subscriptionId}`,
        axiosConfig
      )
      .then(function (response) {
        if (response.status === 200) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "notifications/subscriptions", axiosConfig)
      .then((response) => {
        if (response.status === 204) {
          setNoData(false);
          setSubscriptionsList([]);
        } else {
          setSubscriptionsList(response.data);
          setNoData(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
      });
  }, [bearerToken, refreshData]);

  const [eventType, setEventType] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const isFormComplete = eventType && deliveryMethod;

  let [filtersList, setFiltersList] = useState([]);

  const handleAddFilter = (newFilter) => {
    setFiltersList([...filtersList, newFilter]);
  };
  const handleRemoveFilter = (index) => {
    setFiltersList(filtersList.filter((_, i) => i !== index));
  };

  const resetFormFields = () => {
    setEventType("");
    setDeliveryMethod("");
    setFiltersList([]);
    setSelectedGroupId(null);
  };

  const handleCreateSubscriptionSubmit = (event) => {
    event.preventDefault();
    const createSubscriptionBody = {
      eventType: eventType,
      deliveryMethod: deliveryMethod,
      userMobile: user.mobile,
      eventFilters: filtersList,
      groupId: selectedGroupId,
    };
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .post(
        apiConfig.usersApiUrl + "notifications/subscriptions",
        createSubscriptionBody,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 201) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.message || "Unknown error",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((error) => {
        notyf.open({
          type: "warning",
          message: error.response.data.message || "Unknown error",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
    setDeliveryMethod("");
    setEventType("");
    resetFormFields();
  };

  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [eventTypeMetadataList, setEventTypeMetadataList] = useState({});
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "notifications/eventTypes", axiosConfig)
      .then((response) => {
        let eventTypeList = [];
        let eventTypeMetadataList = {};
        response.data.forEach((eventType) => {
          const eventCategory = eventType.event_type.split(".")[0];

          let eventTypeItem = {
            value: eventType.event_type,
            label:
              eventCategory.charAt(0).toUpperCase() +
              eventCategory.slice(1) +
              " | " +
              eventType.event_friendly_name,
          };
          let eventTypeMetadata = eventType.notification_filters;

          eventTypeMetadataList[eventType.event_type] = eventTypeMetadata;
          eventTypeList.push(eventTypeItem);
        });
        setEventTypeMetadataList(eventTypeMetadataList);
        setEventTypeOptions(eventTypeList);
      })
      .catch((error) => {});
  }, [bearerToken]);

  const [groupOptions, setGroupOptions] = useState([]);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "notifications/groups", axiosConfig)
      .then((response) => {
        const groupList = response.data.map((group) => {
          return {
            value: group.id,
            label: group.group_name,
          };
        });
        setGroupOptions(groupList || []);
      })
      .catch((error) => {});
  }, [bearerToken]);

  return (
    <>
      {loading && (
        <>
          <LogoLoader className="d-block m-auto p-4" />
        </>
      )}
      {!loading && noData && <NotFound />}
      {!loading && !noData && (
        <>
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => toggle("addSubscription")}
          >
            <FontAwesomeIcon icon={faBell} className="me-1" /> Add Notification
            Subscription
          </Button>
          <ColumnFilteringTable
            columns={subscriptionsColumns}
            data={subscriptionsList}
            actions
          />
        </>
      )}

      <Modal
        show={openModals["addSubscription"]}
        onHide={() => {
          toggle("addSubscription");
          resetFormFields();
        }}
        centered
      >
        <Form onSubmit={handleCreateSubscriptionSubmit}>
          <Modal.Header closeButton>
            <b>Notifications:</b>&nbsp; Create Notification Subscription
          </Modal.Header>
          <Modal.Body className="m-3">
            <p className="text-left my-0">
              <>
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>1. Choose an event type: </b>What kind of event do you
                      want to receive notifications about?
                    </Form.Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={eventTypeOptions}
                      onChange={(e) => setEventType(e.value)}
                    />
                  </Form.Group>
                  <hr />
                </>
                {eventType && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>2. Choose a delivery method: </b>How do you want to
                        receive notifications about these events?
                      </Form.Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={[
                          { label: "In-App Only", value: "app" },
                          { label: "App & Email", value: "email" },
                          { label: "App & SMS", value: "sms" },
                          { label: "App, Email & SMS", value: "*" },
                        ]}
                        onChange={(e) => setDeliveryMethod(e.value)}
                      />
                    </Form.Group>
                    <hr />
                  </>
                )}
                {eventType &&
                  deliveryMethod &&
                  eventTypeMetadataList[eventType] && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>3. Select filters: </b>Add filters to this
                          subscription to receive notifications only when all
                          conditions are matched.
                        </Form.Label>
                        <AddEventFilter
                          eventTypeMetadata={eventTypeMetadataList}
                          eventType={eventType}
                          onAddFilter={handleAddFilter}
                        />
                        {filtersList.map((item, key) => {
                          return (
                            <Alert
                              variant="secondary"
                              onClose={() => handleRemoveFilter(key)}
                              dismissible
                            >
                              <div className="alert-message" key={item.key}>
                                <strong>{item.label}</strong> {item.operator}{" "}
                                <strong>"{item.value.label}"</strong>
                              </div>
                            </Alert>
                          );
                        })}
                      </Form.Group>
                      <hr />
                    </>
                  )}
                {eventType &&
                  deliveryMethod &&
                  !eventTypeMetadataList[eventType] && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>3. Select filters: </b>There are no filters
                          available for this event type. A notification will be
                          sent for all events of this type.
                        </Form.Label>
                      </Form.Group>
                      <hr />
                    </>
                  )}
                {eventType && deliveryMethod && (
                  <>
                    <IsAllowed to="create:notifications:groups">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>4. Add a notification group: </b>Do you want to
                          notify a group of users about notifications matching
                          this subscription?
                        </Form.Label>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={groupOptions}
                          onChange={(e) =>
                            setSelectedGroupId(e ? e.value : null)
                          }
                          isClearable
                        />
                      </Form.Group>
                    </IsAllowed>
                  </>
                )}
              </>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                toggle("addSubscription");
                resetFormFields();
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isFormComplete}
              onClick={() => {
                toggle("addSubscription");
                setTimeout(() => {
                  dispatch(setRefreshData());
                }, 2000);
              }}
            >
              Create Subscription
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={openModals["viewSubscriptionFilters"]}
        onHide={() => {
          toggle("viewSubscriptionFilters");
        }}
        passedData={passedData}
        centered
      >
        <Modal.Header closeButton>
          <b>{passedData.event_friendly_name}:</b>&nbsp; Notification Filters
        </Modal.Header>
        <Modal.Body className="m-3">
          {passedData?.event_filters?.length > 0 ? (
            <>
              <p className="text-left my-0 pb-2">
                This subscription will only send notifications when all of the
                following conditions are met:
              </p>
              {passedData.event_filters.map((item, key) => {
                return (
                  <Alert variant="secondary mt-3">
                    <div className="alert-message">
                      <strong>{item.label}</strong> {item.operator}{" "}
                      <strong>"{item.value.label}"</strong>
                    </div>
                  </Alert>
                );
              })}
            </>
          ) : (
            <p className="text-left my-0">
              This subscription will send notifications for all events of this
              type.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              toggle("viewSubscriptionFilters");
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotificationsTable;
