import React from "react";

import { Row, Col, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { apiConfig } from "../../config";

import moment from "moment";

const NavbarDropdownItem = ({
  icon,
  title,
  description,
  time,
  spacing,
  unread,
  link,
  notification_id,
}) => {
  const navigate = useNavigate();
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  let markAsRead = (notification_id, link) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(
        apiConfig.usersApiUrl + "notifications/" + notification_id + "/read",
        null,
        axiosConfig
      )
      .then((response) => {
        if (link) {
          navigate(link);
        } else {
          navigate(`/notifications/view#notification-${notification_id}`);
        }
      });
  };
  return (
    <ListGroup.Item className={"notification " + unread}>
      <Link to="#">
        <Row
          className="align-items-center g-0"
          onClick={() => {
            markAsRead(notification_id, link);
          }}
        >
          <Col xs={2}>{icon}</Col>
          <Col xs={10} className={spacing ? "pl-2" : null}>
            <div className={unread === "" ? "text-muted" : "text-dark"}>
              {title}
            </div>
            <div className="text-muted small mt-1">{description}</div>
            <div className="text-muted small mt-1">
              {moment(time).fromNow()}
            </div>
          </Col>
        </Row>
      </Link>
    </ListGroup.Item>
  );
};

export default NavbarDropdownItem;
