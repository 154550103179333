import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import NewNumUsersDisplay from "../../../../components/wifi/NewNumUsersDisplay";
import useAuth from "../../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../../config";
import NotyfContext from "../../../../contexts/NotyfContext";

const AddNew8021XCredentialModal = ({
  openModals,
  toggle,
  customerOptions,
  dispatch,
  setRefreshData,
}) => {
  const notyf = useContext(NotyfContext);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [excellid, setExcellId] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [SSID, setSSID] = useState("");
  const [accessLevelNum, setAccessLevelNum] = useState(null);
  const [validated, setValidated] = useState(false);

  const [numUsersIsLoading, setNumUsersIsLoading] = useState(true);
  const [numAvailable, setNumAvailable] = useState(0);

  const [availableSsids, setAvailableSsids] = useState([]);

  React.useEffect(() => {
    axios
      .get(apiConfig.credentialsApiUrl + "ssids/enabled", {
        headers: { Authorization: bearerToken },
      })
      .then((response) => {
        const filteredSSIDs = response.data.ssids.rows
          .filter(
            (ssid) =>
              ssid.building_operator_id ===
                parseInt(selectedLandlord.landlordid) &&
              ssid.credential_type === "dot1x"
          )
          .map((ssid) => ({ value: ssid.ssid, label: ssid.ssid }));
        setAvailableSsids(filteredSSIDs);
      });
  }, [selectedLandlord.landlordid, bearerToken]);

  const resetFormFields = () => {
    setFirstName("");
    setLastName("");
    setUsername("");
    setEmail("");
    setAccessLevelNum(null);
    setExcellId(null);
    setSSID(null);
    setValidated(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;

    if (!form.checkValidity()) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    const createCredentialBody = {
      username,
      firstname: firstName,
      lastname: lastName,
      email,
      excellid,
      SSID,
      wavenetIdList: [excellid],
      userlevel: accessLevelNum,
      building_operator_id: selectedLandlord.landlordid,
      sendInviteEmail: true,
    };

    try {
      const response = await axios.post(
        apiConfig.credentialsApiUrl + "users/",
        createCredentialBody,
        { headers: { Authorization: bearerToken } }
      );
      if (response.data.success === 1) {
        notyf.open({
          type: "success",
          message: response.data.message,
          duration: 3000,
          ripple: true,
          dismissible: true,
          position: { x: "right", y: "top" },
        });
        toggle("addNewCredential");
        setTimeout(() => dispatch(setRefreshData()), 2000);
      }
    } catch (error) {
      notyf.open({
        type: "warning",
        message: error.response?.data?.error || "An error occurred.",
        duration: 3000,
        ripple: true,
        dismissible: true,
        position: { x: "right", y: "top" },
      });
    } finally {
      resetFormFields();
    }
  };

  return (
    <Modal
      show={openModals["addNewCredential"]}
      onHide={() => {
        toggle("addNewCredential");
        resetFormFields();
      }}
      centered
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <b>Admin Function:</b> Add New 802.1X Credential
        </Modal.Header>
        <Modal.Body className="m-3">
          <Form.Group className="mb-3">
            <Form.Label>Company Name</Form.Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={customerOptions}
              onChange={(e) => setExcellId(e.value)}
              value={
                excellid
                  ? customerOptions.find((o) => o.value === excellid)
                  : null
              }
            />
          </Form.Group>

          {excellid && (
            <NewNumUsersDisplay
              excellid={excellid}
              isLoading={numUsersIsLoading}
              setIsLoading={setNumUsersIsLoading}
              numAvailable={numAvailable}
              setNumAvailable={setNumAvailable}
            />
          )}

          {excellid && !numUsersIsLoading && numAvailable > 0 && (
            <>
              <hr />
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  First name is required.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Last name is required.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value.trim());
                    setEmail(e.target.value.trim());
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>SSID</Form.Label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={availableSsids}
                  onChange={(e) => setSSID(e.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select an SSID.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Access Level</Form.Label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={[
                    { value: 1, label: "Web & LAN" },
                    { value: 0, label: "Web Only" },
                  ]}
                  onChange={(e) => setAccessLevelNum(e.value)}
                />
              </Form.Group>
            </>
          )}

          {excellid && !numUsersIsLoading && numAvailable <= 0 && (
            <Alert className="my-3 p-2">
              This account does not have any available wi-fi users.{" "}
              <a href="#" onClick={() => toggle("wifiPurchase")}>
                Request more.
              </a>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => toggle("addNewCredential")}
          >
            Cancel
          </Button>
          <Button type="submit" variant="info">
            Create Credential
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddNew8021XCredentialModal;
