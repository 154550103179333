import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamation,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { RadioTile, RadioTileGroup, Message, Table } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { quantum, dotPulse } from "ldrs";
import { LazyLog } from "react-lazylog";
import { useNavigate } from "react-router-dom";
import { useDynamicSelectOptions, useExternalQuery } from "./hooks";
import {
  TextField,
  PrefilledTextField,
  DynamicSelectField,
  DynamicMultipleSelectField,
  SelectField,
  CheckboxField,
  SwitchField,
  DateField,
  RangeField,
  RadioTileField,
  RadioGroupField,
  ConfirmationField,
} from "./FormFields";
import { renderErrorMessages } from "./utils";

const { Column, HeaderCell, Cell } = Table;
quantum.register();
dotPulse.register();

const FormPage = ({
  formConfig,
  stepConfig,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  values,
  validateField,
  setFieldTouched,
  myFormData,
  setMyFormData,
  isSubmissionStep,
  submissionStatus,
  isConfirmationNeeded,
  handleUserConfirmation,
  userAccessToken,
  selectedLandlord,
  userObject,
}) => {
  const [passedResult, setPassedResult] = useState({});

  const { loading: loadingDynamicSelect, options: dynamicSelectOptions } =
    useDynamicSelectOptions(
      stepConfig.fields,
      userAccessToken,
      selectedLandlord,
      myFormData
    );

  const {
    loading: loadingExternalQuery,
    results: externalQueryResults,
    error: externalQueryError,
  } = useExternalQuery(
    stepConfig,
    myFormData,
    userAccessToken,
    setMyFormData,
    selectedLandlord
  );

  const navigate = useNavigate();

  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  useEffect(() => {
    if (submissionStatus.tasks) {
      submissionStatus.tasks.forEach((task) => {
        if (
          task.status === "loading" ||
          task.status === "succeeded" ||
          task.status === "failed"
        ) {
          setPassedResult(task.payload);
        }
      });
    }
  }, [submissionStatus.tasks]);

  if (isSubmissionStep && submissionStatus.tasks) {
    return (
      <div className="pb-4">
        <h3 className="text-dark display-6">{stepConfig.title}</h3>
        {submissionStatus.tasks.map((task, index) => (
          <div key={index} className="fs-4 m-3">
            {task.status === "loading" && (
              <l-quantum size="15" color="#3F80EA" fixedWidth></l-quantum>
            )}
            {task.status === "pending" && (
              <FontAwesomeIcon icon={faPause} fixedWidth />
            )}
            {(task.status === "succeeded" || task.status === "no_action") && (
              <FontAwesomeIcon
                icon={faCheck}
                className="text-success"
                fixedWidth
              />
            )}
            {task.status === "failed" && (
              <FontAwesomeIcon
                icon={faExclamation}
                className="text-warning"
                fixedWidth
              />
            )}
            <strong className="ms-3">{task.name}</strong>
            {task.description && (
              <p className="text-small text-muted fs-6 ms-5 mb-0">
                {task.description}
              </p>
            )}
            {/*{task.status === "loading" && (
              <>
                <Button
                  className="m-2"
                  variant="light"
                  size="md"
                  onClick={() => {
                    toggle("viewResult");
                    setPassedResult(task.payload);
                  }}
                >
                  View Progress
                </Button>
              </>
            )} */}
            {(task.status === "succeeded" ||
              task.status === "loading" ||
              task.status === "failed") && (
              <div
                className={
                  task.status === "failed"
                    ? "text-warning fs-6 ms-5"
                    : "text-success fs-6 ms-5"
                }
              >
                {task.status === "succeeded"
                  ? task.message
                  : task.status === "failed"
                  ? task.message
                  : "Task in progress..."}
                <br />
                {/* <a href="#" onClick={() => toggle("viewManifest")}>
                  Click to view
                </a> */}
                {task.status !== "no_action" && task.payload && (
                  <Button
                    className="m-2"
                    variant="light"
                    size="md"
                    onClick={() => {
                      toggle("viewResult");
                      setPassedResult(task.payload);
                    }}
                  >
                    {task.status === "loading"
                      ? "View Progress"
                      : "View Results"}
                  </Button>
                )}

                {isConfirmationNeeded && (
                  <Button
                    className="m-2"
                    variant="success"
                    size="md"
                    onClick={handleUserConfirmation}
                  >
                    {task.continueText
                      ? task.continueText
                      : "Continue Workflow"}
                  </Button>
                )}

                {task.ctaLink && (
                  <Button
                    className="m-2"
                    variant="light"
                    size="md"
                    onClick={() => {
                      toggle(formConfig.name);
                      navigate(task.ctaLink.link);
                    }}
                  >
                    {task.ctaLink.label}
                  </Button>
                )}
                <Modal
                  show={openModals["viewResult"]}
                  onHide={() => {
                    toggle("viewResult", task.payload.task_id);
                    setPassedResult({});
                  }}
                  size="xl"
                  centered
                  scrollable
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Task logs</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-2">
                    {passedResult ? (
                      <LazyLog
                        text={passedResult}
                        selectableLines={true}
                        height={500}
                        enableSearch={true}
                        extraLines={1}
                        follow={true}
                      />
                    ) : (
                      <LazyLog
                        text={"Loading..."}
                        selectableLines={true}
                        height={500}
                        enableSearch={true}
                        extraLines={1}
                        follow={true}
                      />
                    )}
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            )}
            {/* {task.status === "failed" && (
              <div className="text-warning fs-6 ms-5">
                {task.message}
                <br />
                <a href="#">Start troubleshooting</a>
              </div>
            )} */}
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      <div className="pb-4">
        <h3 className="text-dark display-6">{stepConfig.title}</h3>
      </div>
      {stepConfig.longDescription && (
        <Message type="info" className="mb-4">
          {stepConfig.longDescription}
        </Message>
      )}

      {["input", "review"].includes(stepConfig.stepType) && (
        <div className="fv-row mb-6">
          {stepConfig.fields.map((field, index) => {
            const shouldDisplay = field.condition
              ? field.condition(values)
              : true;

            if (!shouldDisplay) {
              return null;
            }

            switch (field.type) {
              case "text":
                return (
                  <TextField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "prefilledtext":
                return (
                  <PrefilledTextField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    prefill={userObject[field.prefill]}
                  />
                );
              case "dynamicSelect":
                return (
                  <DynamicSelectField
                    field={field}
                    dynamicSelectOptions={dynamicSelectOptions}
                    loadingDynamicSelect={loadingDynamicSelect}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "dynamicMultiSelect":
                return (
                  <DynamicMultipleSelectField
                    field={field}
                    dynamicSelectOptions={dynamicSelectOptions}
                    loadingDynamicSelect={loadingDynamicSelect}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "select":
                return (
                  <SelectField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "checkbox":
                return (
                  <CheckboxField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "switch":
                return (
                  <SwitchField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "date":
                return (
                  <DateField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "range":
                return (
                  <RangeField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "radiotile":
                return (
                  <RadioTileField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "radiogroup":
                return (
                  <RadioGroupField
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateField={validateField}
                    setMyFormData={setMyFormData}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                );
              case "confirm":
                return (
                  <ConfirmationField
                    field={field}
                    formConfig={formConfig}
                    myFormData={myFormData}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
      )}

      {["externalQuery__hubspot"].includes(stepConfig.stepType) && (
        <>
          {stepConfig.fields.map((field, index) => {
            if (field.type === "radiotile") {
              return (
                <div
                  className="fv-row mb-6 scrollable-dropdown"
                  style={{ height: "300px" }}
                  key={index}
                >
                  {loadingExternalQuery ? (
                    <div className="text-center">
                      <l-quantum
                        size="15"
                        color="#3F80EA"
                        fixedWidth
                      ></l-quantum>
                      <br />
                      Searching for Hubspot for matching customers...
                    </div>
                  ) : (
                    <RadioTileGroup
                      inline={false}
                      aria-label="Radiotile"
                      value={values[field.name]}
                      onChange={(value) => {
                        setFieldValue(field.name, value);
                        setFieldTouched(field.name, true, false);
                        setMyFormData({
                          ...myFormData,
                          [field.name]: value,
                        });
                      }}
                    >
                      {externalQueryResults.map((company, index) => {
                        return (
                          // <RadioTile
                          //   key={index}
                          //   label={company.name}
                          //   value={
                          //     company.symbillAccounts.length > 0
                          //       ? company.hubspotId + "*"
                          //       : company.hubspotId
                          //   }
                          // >
                          <RadioTile
                            key={index}
                            label={company.name}
                            value={company.hubspotId}
                          >
                            <Row className="w-100">
                              <Col sm={12}>Domain: {company.domain}</Col>
                              <Col sm={12}>Phone: {company.phone}</Col>
                              <Col sm={12}>
                                Registration Number:{" "}
                                {company.registrationNumber}
                              </Col>
                              <Col sm={12}>
                                Symbill Account Number:{" "}
                                {company.symbillAccounts.length > 0
                                  ? company.symbillAccounts[0].accountNumber +
                                    (company.symbillAccounts.length > 1
                                      ? " (+" +
                                        (company.symbillAccounts.length - 1) +
                                        " more)"
                                      : "")
                                  : "-"}
                              </Col>
                            </Row>
                            <span className="text-muted float-end">
                              <a
                                href={`https://app-eu1.hubspot.com/contacts/3402328/company/${company.hubspotId}`}
                                target="_blank"
                                rel="noreferrer"
                                className="link-info"
                              >
                                View In Hubspot
                              </a>
                            </span>
                          </RadioTile>
                        );
                      })}

                      <RadioTile
                        label={
                          externalQueryResults.length > 0
                            ? "None of the above"
                            : "No matches"
                        }
                        value="None"
                      >
                        {externalQueryResults.length > 0
                          ? "The customer you want to register is not listed"
                          : "Continue with new customer registration"}
                      </RadioTile>
                    </RadioTileGroup>
                  )}
                  {errors[field.name] && touched[field.name] && (
                    <div className="mt-1">
                      {renderErrorMessages(errors[field.name])}
                    </div>
                  )}
                </div>
              );
            }
            if (field.type === "hidden") {
            }
            return null;
          })}
        </>
      )}

      {["externalQuery__hubspot_order"].includes(stepConfig.stepType) && (
        <>
          {stepConfig.fields.map((field, index) => {
            if (field.type === "radiotile") {
              return (
                <>
                  <div
                    className="fv-row mb-6 scrollable-dropdown"
                    style={{ height: "300px" }}
                    key={index}
                  >
                    {loadingExternalQuery ? (
                      <div className="text-center">
                        <l-quantum
                          size="15"
                          color="#3F80EA"
                          fixedWidth
                        ></l-quantum>
                        <br />
                        Searching for Hubspot for matching customers...
                      </div>
                    ) : (
                      <RadioTileGroup
                        inline={false}
                        aria-label="Radiotile"
                        value={values[field.name]}
                        onChange={(value) => {
                          setFieldValue(field.name, value);
                          setFieldTouched(field.name, true, false);
                          setMyFormData({
                            ...myFormData,
                            [field.name]: value,
                          });
                        }}
                      >
                        {externalQueryResults.map((company, index) => {
                          return (
                            // <RadioTile
                            //   key={index}
                            //   label={company.name}
                            //   value={
                            //     company.symbillAccounts.length > 0
                            //       ? company.hubspotId + "*"
                            //       : company.hubspotId
                            //   }
                            // >
                            <RadioTile
                              key={index}
                              label={company.name}
                              value={company.hubspotId}
                            >
                              <Row className="w-100">
                                <Col sm={12}>Domain: {company.domain}</Col>
                                <Col sm={12}>Phone: {company.phone}</Col>
                                <Col sm={12}>
                                  Registration Number:{" "}
                                  {company.registrationNumber}
                                </Col>
                                <Col sm={12}>
                                  Symbill Account Number:{" "}
                                  {company.symbillAccounts.length > 0
                                    ? company.symbillAccounts[0].accountNumber +
                                      (company.symbillAccounts.length > 1
                                        ? " (+" +
                                          (company.symbillAccounts.length - 1) +
                                          " more)"
                                        : "")
                                    : "-"}
                                </Col>
                              </Row>
                              <span className="text-muted float-end">
                                <a
                                  href={`https://app-eu1.hubspot.com/contacts/3402328/company/${company.hubspotId}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="link-info"
                                >
                                  View In Hubspot
                                </a>
                              </span>
                            </RadioTile>
                          );
                        })}
                        {externalQueryResults.length === 0 && (
                          <div className="text-center">No results found</div>
                        )}
                      </RadioTileGroup>
                    )}
                  </div>
                  {errors[field.name] && touched[field.name] && (
                    <div className="mt-1 mb-2">
                      {renderErrorMessages(errors[field.name])}
                    </div>
                  )}
                </>
              );
            }
            if (field.type === "hidden") {
            }
            return null;
          })}
        </>
      )}

      {["externalQuery__pricing"].includes(stepConfig.stepType) && (
        <>
          <div className="mb-4">
            {loadingExternalQuery ? (
              <div className="text-center">
                <l-quantum size="15" color="#3F80EA" fixedWidth></l-quantum>
                <br />
                Calculating order price summary
              </div>
            ) : (
              <>
                <Table
                  data={externalQueryResults}
                  hover={false}
                  className="my-2"
                  renderEmpty={() => {
                    return (
                      <div className="text-muted text-center m-3">
                        {externalQueryError === ""
                          ? "Could not calculate pricing"
                          : externalQueryError}
                      </div>
                    );
                  }}
                >
                  <Column flexGrow={2}>
                    <HeaderCell>Product</HeaderCell>
                    <Cell dataKey="name" />
                  </Column>
                  {/* <Column>
                    <HeaderCell>Unit Price</HeaderCell>
                    <Cell>{(rowData) => `£${rowData.unitPrice}`}</Cell>
                  </Column> */}
                  <Column>
                    <HeaderCell>Quantity</HeaderCell>
                    <Cell dataKey="qty" />
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Price</HeaderCell>
                    <Cell>{(rowData) => `£${rowData.price}`}</Cell>
                  </Column>
                  <Column>
                    <HeaderCell>Charge Type</HeaderCell>
                    <Cell>
                      {(rowData) =>
                        `${
                          rowData.interval.charAt(0).toUpperCase() +
                          rowData.interval.slice(1)
                        }`
                      }
                    </Cell>
                  </Column>
                </Table>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FormPage;
