import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Card,
  Button,
  Badge,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { apiConfig } from "../../config";
import axios from "axios";
import moment from "moment";

import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";
import NotFound from "../../components/NotFound";
import MultiPageForm from "../../components/modals/MultiPageForm";
import formConfig from "./formConfigs/customerOrder";

const CustomerOrdersList = () => {
  const { userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [formPrefillData, setFormPrefillData] = useState({});
  const [formInitialPage, setFormInitialPage] = useState(1);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index, prefillData = {}, initialPage = 1) => {
    if (Object.keys(prefillData).length > 0) {
      setFormPrefillData(prefillData);
      setFormInitialPage(initialPage);
    }

    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (!isAuthenticated) {
      return;
    }
    axios
      .get(
        `${apiConfig.hubspotApiUrl}orders/?building_operator_id=${selectedLandlord.landlordid}`,
        axiosConfig
      )
      .then((response) => {
        if (response.data.orders.orders.length === 0) {
          setOrdersData([]);
        } else {
          setOrdersData(response.data.orders.orders);
        }
        setLoading(false);
      })
      .catch((error) => {
        setNoData(true);
        setLoading(false);
        setOrdersData([]);
      });
  }, [isAuthenticated, bearerToken, refreshData, selectedLandlord.landlordid]);

  const statusColourMap = {
    draft: "primary",
    placed: "info",
    complete: "info",
    signed: "success",
    rejected: "danger",
  };

  const registrationsListColumns = [
    {
      accessor: "hubspot_deal_id",
    },
    {
      Header: "Deal Name",
      accessor: "deal_name",
    },
    {
      Header: "Owner",
      accessor: "owner",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => (
        <Badge bg={statusColourMap[e.value]}>
          {e.row.original.status.charAt(0).toUpperCase() +
            e.row.original.status.slice(1)}
        </Badge>
      ),
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: (e) => moment(e.value).local().format("DD-MM-YYYY HH:mm"),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => {
        return (
          <>
            <Button
              size="sm"
              className="btn-light"
              onClick={() => {
                window.open(
                  `https://app-eu1.hubspot.com/contacts/3402328/deal/${tableProps.row.original.hubspot_deal_id}`,
                  "_blank"
                );
              }}
            >
              View In Hubspot
            </Button>
            <Button
              size="sm"
              className="btn-primary mt-1"
              onClick={() => {
                handleDealHubLink(tableProps.row.original.hubspot_deal_id);
              }}
            >
              View In Dealhub
            </Button>
          </>
        );
      },
    },
  ];

  const getDealHubAuthToken = async () => {
    try {
      const axiosConfig = {
        headers: { Authorization: bearerToken },
      };
      if (!isAuthenticated) {
        return;
      }
      const response = await axios.get(
        `${apiConfig.hubspotApiUrl}orders/get_quote_auth/${selectedLandlord.landlordid}`,
        axiosConfig
      );
      return response.data.quoteAuthToken;
    } catch (err) {}
  };

  const handleDealHubLink = async (opportunity_id = "29328617704") => {
    try {
      const dealHubAuth = await getDealHubAuthToken();
      const axiosConfig = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${dealHubAuth}` },
      };
      const response = await axios.post(
        `https://api-eu1.dealhub.io/api/v1/create/quote`,
        {
          opportunity_id: opportunity_id,
        },
        axiosConfig
      );
      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (err) {}
  };
  return (
    <>
      <Card>
        <Card.Header>
          <div className="card-actions float-end">
            <DropdownButton
              as={ButtonGroup}
              id={`dropdown-variants-primary`}
              variant={`primary`}
              title="Actions "
              align="end"
            >
              <Dropdown.Item onClick={() => toggle("customerOrder")}>
                New Customer Order
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <Card.Title className="mb-0">Customer Orders List</Card.Title>
        </Card.Header>
        <Card.Body className="py-0">
          {loading && (
            <>
              <LogoLoader className="d-block m-auto p-4" />
            </>
          )}
          {!loading && noData && <NotFound />}
          {!loading && !noData && (
            <ColumnFilteringTable
              columns={registrationsListColumns}
              data={ordersData}
              hiddenColumns={["hubspot_deal_id"]}
            />
          )}
        </Card.Body>
      </Card>
      <MultiPageForm
        openModals={openModals}
        toggle={toggle}
        config={formConfig}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
        prefillData={formPrefillData}
        initialPage={formInitialPage}
      />
    </>
  );
};

export default CustomerOrdersList;
